import { sendAmplitudeEvent } from "modules/utils/events"
import React, { useEffect } from "react"
import AdsStat from "../partials/AdsStat"
import AdsIndex from "./AdsIndex"

function Home() {
  useEffect(() => {
    // useEffect doesn't run on server-side
    sendAmplitudeEvent("visit_homepage", {})
  }, [])

  return (
    <div>
      <AdsStat />
      <section id="recent_cases">
        <div className="container-fluid">
          <AdsIndex isFromHomePage={true} />
        </div>
      </section>
    </div>
  )
}

export default Home
