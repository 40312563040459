import React from "react"
import { connect } from "react-redux"
import { push, Push } from "connected-react-router"
import { Flex, Heading, Button, List, ListItem, ListIcon, Stat, StatLabel, StatNumber, StatGroup } from "@chakra-ui/core"

import { IAppStore } from "reducers"
import { adsCountersFetch } from "modules/ads/ads.actions"
import getLoader from "modules/utils/loader"
import styled from "modules/utils/theme"

interface IAdsStatStateProps {
  createdAdsToday: number
  createdAdsAtWeek: number
  createdAdId?: number
}

interface IAdsStatDispatchProps {
  adsCountersFetch(): void
  push: Push
}

interface IAdsStatProps extends IAdsStatStateProps, IAdsStatDispatchProps {}

const GradientFlex = styled(Flex)`
  background: ${(p) => p.theme.colors.purple[400]}; // fallback
  background: radial-gradient(circle, ${(p) => p.theme.colors.purple[400]} 0%, ${(p) => p.theme.colors.cyan[400]} 100%);
`

export class AdsStat extends React.Component<IAdsStatProps> {
  constructor(props: IAdsStatProps) {
    super(props)

    // Run it from constructor because componentDidMount isn't called in SSR
    // TODO: use https://reactjs.org/docs/concurrent-mode-suspense.html
    if (props.createdAdsToday === null) {
      props.adsCountersFetch()
    }
  }

  public render() {
    const { createdAdsToday, createdAdsAtWeek, createdAdId } = this.props

    return (
      <GradientFlex direction="column" align="center" padding={3} color="white">
        <Heading>Объявления о пропавших собаках, кошках и животных</Heading>
        <List marginTop={2}>
          <ListItem>
            <ListIcon icon="check-circle" color="white" />
            Бесплатно и без регистрации
          </ListItem>
          <ListItem>
            <ListIcon icon="star" color="white" />
            Размещение в социальных сетях
          </ListItem>
          <ListItem>
            <ListIcon icon="search" color="white" />
            Большая база объявлений:
          </ListItem>
          {createdAdsToday !== null ? (
            <StatGroup marginTop={1}>
              <Stat>
                <StatLabel>за сегодня</StatLabel>
                <StatNumber>{createdAdsToday}</StatNumber>
              </Stat>

              <Stat>
                <StatLabel>за 7 дней</StatLabel>
                <StatNumber>{createdAdsAtWeek}</StatNumber>
              </Stat>
            </StatGroup>
          ) : (
            getLoader()
          )}
        </List>

        {!createdAdId && (
          <Button marginTop={2} variantColor="pink" size="lg" onClick={() => this.props.push("/ads/new")}>
            Создать объявление!
          </Button>
        )}
      </GradientFlex>
    )
  }
}

const mapStateToProps = ({ ads }: IAppStore): IAdsStatStateProps => {
  const counters = ads.counters
  return {
    createdAdsToday: counters ? counters.today : null,
    createdAdsAtWeek: counters ? counters.week : null,
    createdAdId: ads.session.createdAdId,
  }
}

const mapDispatchToProps: IAdsStatDispatchProps = {
  adsCountersFetch,
  push,
}

export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(AdsStat)
